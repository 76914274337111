import React, { useState, useEffect, Suspense, useRef } from "react";
import { apiCalling, awsAPIUrlcommon } from "../../apiService";
import { useLocation, useHistory } from "react-router-dom";
import Closebutton from "../../image/icons/close-sm.png";
import $ from "jquery";
import upload from "../../image/media/upload.png";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import AWS from "aws-sdk";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../../" + folderName + "/header"));
const Footer = React.lazy(() => import("../../" + folderName + "/footer"));
const AddEditIdaStore = () => {
  const location = useLocation();
  const history = useHistory();
  const newQuestionRef = useRef(null);
  let data
  if (location?.state) {
    ({ data } = location.state);
  }
  const [pollDetails, setPollDetails] = useState({
    pollId: "",
    pollName: "",
    pollStartTime: "",
    pollEndTime: "",
    description: "",
  })
  const [imageDeatils, setimageDeatils] = useState({
    AWSAccessKeyId: "",
    AwsCheckAccessKey: "",
    region: "us-east-1",
    bucket: "",
    bucketFolder: "",
  })
  const [questions, setQuestions] = useState([
    {
      question: "",
      answers: ["", ""],
      image: "",
      answerType: "",
      answerMandatoryOrNot: "",
      textBoxAddedOrNot: "",
    }
  ]);
  const [imageUploadFlag, setImageuploadFlag] = useState(true)

  ///////////////////////////QuestionsSections
  const addQuestion = () => {
    setQuestions(prevQuestions => [
      ...prevQuestions,
      { question: "", answers: ["", ""], image: "", answerType: "", answerMandatoryOrNot: "", textBoxAddedOrNot: "" }
    ]);
    setTimeout(() => {
      if (newQuestionRef.current) {
        newQuestionRef.current.focus()
        newQuestionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };
  const addOption = (index) => {
    setQuestions(prevQuestions => {
        const updatedQuestions = [...prevQuestions];
        if (updatedQuestions[index]) {
            if (!Array.isArray(updatedQuestions[index].answers)) {
                updatedQuestions[index].answers = [];
            }
            updatedQuestions[index].answers.push("");
        } else {
            console.error(`No question found at index ${index}`);
        }
        return updatedQuestions;
    });
    const newOptionDiv = document.getElementById(`optionAddDiv_${index}`);
    if (newOptionDiv) {
        newOptionDiv.scrollIntoView({ behavior: 'smooth' });
    }
};
  const handleInputChange = (index, optionIndex, value) => {
    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      if (optionIndex === -1) {
        updatedQuestions[index].question = value;
      } else {
        updatedQuestions[index].answers[optionIndex] = value;
      }
      return updatedQuestions;
    });
  };
  useEffect(() => {
    imageUploadHandler()
    if (data) { getPollUpdateDetails(data) }
  }, []);
  const getPollUpdateDetails = (pollId) => {
    const postData = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "getSurveyDetails",
        "pollId": pollId,
        "userId": window.sessionStorage.getItem("userId"),
      }),
    };
    awsAPIUrlcommon(postData).then((data) => {
      if (data.success === "1") {
        const updatedQuestions = data?.result?.pollData[0]?.pollQuestions.map(data => ({
          question: data?.pollQuestions,
          answers: data?.pollAnswerData?.pollQuestionsAnswer?.split(','), // Split the answers string into an array
          image: data.poll_image_name, // You may need to update this based on your requirements
          answerType: data.pollAnswerType,
          answerMandatoryOrNot: data.answer_mandatory_or_not,
          textBoxAddedOrNot: data.text_box_added_or_not
        }));
        setQuestions(
          updatedQuestions
        );
        if (data?.result?.pollData[0]) {
          setPollDetails({
            pollId: pollId,
            pollName: data.result.pollData[0].pollName,
            pollStartTime: data.result.pollData[0].pollStartDate,
            pollEndTime: data.result.pollData[0].pollEndDate,
            description: data.result.pollData[0].pollDescription,
          });
        }
      } else { alert("There is an error while Fetching Your data") }

    }).catch((error) => {
      console.error("Error sending notification:", error);
      alert("An error occurred while Getting  Data");
    });
  }
  const imageUploadHandler = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        setimageDeatils({
          AwsCheckAccessKey: data.configPathDetails[0].AwsCheckAccessKey,
          AWSAccessKeyId: data.configPathDetails[0].AwsCheckSecretKeyId,
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
        });
      }
    });
  };
  const handleRemoveOption = (questionIndex, optionIndex) => {
    setQuestions(prevQuestions => 
        prevQuestions?.map((question, qIndex) => {
            if (qIndex === questionIndex && Array.isArray(question.answers)) {
                return {
                    ...question,
                    answers: question?.answers?.filter((_, aIndex) => aIndex !== optionIndex)
                };
            }
            return question;
        })
    );
};
  const handleRemoveQuestion = (questionIndex) => {
    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions.splice(questionIndex, 1);
      return updatedQuestions;
    });
  };
  const fileChange = (e, index) => {
    const file = e.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG, JPEG, PNG format");
      return;
    }

    if (file.size > 3145728) {
      alert("File size must be under 3 MB!");
      return;
    }

    fileUpload(file, index); // Pass index to fileUpload
  };
  const fileUpload = (file, index) => {
    setImageuploadFlag(false)
    const S3BucketMainFolder = "";
    const fileName = uuidv4() + file.name;
    const fullFilePath = S3BucketMainFolder + fileName;

    AWS.config.update({
      accessKeyId: imageDeatils.AwsCheckAccessKey,
      secretAccessKey: imageDeatils.AWSAccessKeyId,
      region: imageDeatils.region,
    });

    const bucketParams = {
      Bucket: `${imageDeatils.bucket}/${imageDeatils.bucketFolder}/attachments`,
    };

    const s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          setImageuploadFlag(true)
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          setQuestions(prevQuestions => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[index] = {
              ...updatedQuestions[index],
              image: data.Location
            };
            return updatedQuestions;
          });
          setImageuploadFlag(true)
        }
      }
    );
  };
  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  const handleAnswerTypeChange = (index, property, value) => {
    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index][property] = value; // Update the property directly
      return updatedQuestions;
    });
  };
  const imageRemove = (index) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index].image = "";
      return updatedQuestions;
    });
  };
  const submitClick = () => {
    if (!pollDetails.pollName) {
      alert("Please provide a name for the Poll.");
      return;
    }
    if (!pollDetails.pollStartTime) {
      alert("Please provide a start date for the poll.");
      return;
    }
    if (!pollDetails.pollEndTime) {
      alert("Please provide an end date for the poll.");
      return;
    }
    if ((new Date(pollDetails.pollStartTime).getTime()) > (new Date(pollDetails.pollEndTime).getTime())) {
      alert("Start date must be less than end date")
      return
    }
    // if (!pollDetails.description) {
    //   alert("Please provide a description for the poll.");
    //   return;
    // }
    if (isQuestionsValid()) {
      $(".loader").show();
      const updatedQuestions = questions.map(q => ({
        ...q,
        answerType:(!q.answerType&& q.textBoxAddedOrNot === 1)? "TextBox" : q.answerType,
        answers: q.answers?.filter(ans => ans.trim() !== "")
      }));
      const apiJson = {
        method: "POST",
        body: JSON.stringify(
          {
            "functionName": "saveSurveyDetails",
            "pollName": pollDetails.pollName?.replace(/['"]/g, "\\$&"),
            "pollStartDate": new Date(pollDetails.pollStartTime).getTime(),
            "pollEndDate": new Date(pollDetails.pollEndTime).getTime(),
            "createdOnDate": Date.now(),
            "conferenceName": "",
            "pollDescription": pollDetails.description?.replace(/['"]/g, "\\$&"),
            "siteId": window.sessionStorage.getItem("siteId"),
            "userId": window.sessionStorage.getItem("userId"),
            "pollId": pollDetails.pollId,
            "pollQuestions":updatedQuestions
          }
        ),
      };
      awsAPIUrlcommon(apiJson).then((data) => {
        if (data.success === "1") {
          alert(data.successMessage)
          $(".loader").hide();
          history.push("/adminManagement/poll-list");
        } else {
          $(".loader").hide();
          alert("There is an error while Saving Your Poll ")
          history.push("/adminManagement/poll-list");
        }
      }).catch((error) => {
        console.error("Error sending notification:", error);
        alert("An error occurred while Saving Details");
      });
    }
  }
  const handleInputChangeOnType = (e) => {
    const { name, value } = e.target;
    setPollDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const handleStartTimeChange = (date) => {
    if (date !== null) {
      date.setHours(10);
      date.setMinutes(0);
      date.setSeconds(0);
      setPollDetails(prevDetails => ({
        ...prevDetails,
        pollStartTime: date,
      }));
    }
  };
  const handleEndTimeChange = (date) => {
    if (date !== null) {
      date.setHours(10);
      date.setMinutes(0);
      date.setSeconds(0);
      setPollDetails(prevDetails => ({
        ...prevDetails,
        pollEndTime: date,
      }));
    }
  };
  const scrollToQuestion = (index) => {
    const questionElement = document.getElementById(`Question${index + 1}`);
    const rect = questionElement.getBoundingClientRect();
    if (questionElement) {
      const windowTop = rect.top + window.scrollY;
      setTimeout(() => {
        window.scrollTo({
          top: windowTop,
          behavior: 'smooth'
        });
      }, 100);

      questionElement.focus();
    }
  };
  const isQuestionsValid = () => {
    for (let i = 0; i < questions.length; i++) {
      if (!questions[i].question.trim()) {
        alert(`Question ${i + 1} is empty.`);
        scrollToQuestion(i)
        return false;
      }
      else if (questions[i].answerMandatoryOrNot && (!questions[i].textBoxAddedOrNot && !questions[i].answerType && !questions[i].textBoxAddedOrNot != 1)) {
        alert('Please Check Add Text Box or a question type for Question ');
        return false;
      }

      else if (!questions[i].answerType && questions[i].textBoxAddedOrNot != 1) {
        alert(`Please choose a question type for Question ${i + 1}.`);
        return false;
      }

      else if (questions[i].answerType) {
        for (let j = 0; j < questions[i].answers.length; j++) {
          if (!questions[i].answers[j].trim()) {
            alert(`Option ${j + 1} for Question ${i + 1} is empty.`);
            return false;
          }
        }
      }
    }
    return true;
  };
  const cancelClick = () => {
    if (data) { getPollUpdateDetails(data)
      return
     }
    setPollDetails(prevDetails => ({
      ...prevDetails,
      pollName: "",
      pollStartTime: "",
      pollEndTime: "",
      description: "",
    }));
    setQuestions([
      {
        question: "",
        answers: ["", ""],
        image: "",
        answerType: "",
        answerMandatoryOrNot: "",
        textBoxAddedOrNot: "",
      }
    ])
    // window.location.href = "/adminManagement/PollList"
  }
  const goback=()=>{
    history.push("/adminManagement/poll-list");
  }
  return (
    <div className="purpleWrap bg-clr-wthHead">
      <Suspense fallback={<div>Waiting...</div>}>
        <section>
          <Header />
          <div className="loader"></div>
          <div className="container mb-5">
            <div>
              <ul className="breadcrum-adminMangmnt">
                <li>
                  <a href="/admin/dashboard">Dashboard</a>
                </li>
                <li>
                  <a href="/adminManagement/poll-list">Poll List</a>
                </li>
                <li>
                  <a href="" className="current">Create Poll</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="addrepwrapperborder container pt-5 m-auto text-left purple_edit">
            <div className="row">
              <div className="col-md-8">
                <div className="col-md-12 mb-3">
                  <div className="row">
                    <div className="col-md-3">
                      Poll Name<span className="text-danger">*</span>
                    </div>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        name="pollName"
                        placeholder="Poll Name"
                        value={pollDetails.pollName}
                        onChange={handleInputChangeOnType}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="row">
                    <div className="col-md-3">
                      Description
                    </div>
                    <div className="col-md-9">
                      <textarea
                        className="form-control"
                        name="description"
                        placeholder="Description"
                        value={pollDetails.description}
                        onChange={handleInputChangeOnType}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="col-md-12 mb-3">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      Poll Start Date<span className="text-danger">*</span>
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        selected={pollDetails?.pollStartTime ? new Date(pollDetails.pollStartTime) : null}
                        className="mb-0 form-control"
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                        peekNextMonth
                        showYearDropdown
                        placeholderText="Start Date"
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        minDate={new Date()}
                        strictParsing
                        onChange={handleStartTimeChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      Poll End Date<span className="text-danger">*</span>
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        selected={pollDetails?.pollEndTime ? new Date(pollDetails.pollEndTime) : null}
                        onChange={handleEndTimeChange}
                        className="mb-0 form-control"
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                        placeholderText="End Date"
                        strictParsing
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12" id="addQuestionDiv">
                <div className="row mx-0 px-3 my-3">
                  <div className="col-md-12 row justify-content-between pr-0">
                    <div className="head-hospMangmntList mb-0">Questions</div>
                    <div className="AddNewHspbtnDiv w-auto">
                      <button className="addnewhspbtn mb-0" onClick={addQuestion}>
                        Add Question
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12 px-0"> <hr></hr> </div>
                </div>
                {questions.map((question, index) => (
                  <div key={index} className="col-md-12" id={`questionDiv_${index}`}>
                    <div className="row mx-0 px-3 pt-3 my-3 bg-grey position-relative">
                      <div className="col-md-12 mb-3">
                        <div className="row">
                          <div className="col-md-12 mb-2">
                            <div className="row">
                              <div className="col-md-12 d-flex align-items-center mt-2">
                                <div className="hspinfo-btnupload mb-0">
                                  <span>Upload</span>
                                  <input
                                    type="file"
                                    onChange={(e) => fileChange(e, index)}
                                    className="hspinfoInputfieldupload"
                                  />
                                </div>
                                <div className="file_img ml-2 position-relative">
                                  {question && question.image &&
                                    <img className="poll_close_div01" onClick={() => imageRemove(index)} src={Closebutton} width={18} height={18}></img>
                                  }
                                  <img
                                    id="image"
                                    src={question.image}
                                    width="50"
                                    alt={upload}
                                    onError={(e) => {
                                      e.target.onerror = null; // Prevent infinite loop in case the placeholder image is also broken
                                      e.target.src = upload; // Provide the URL of a placeholder image or a default image
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 mt-2 mt-md-1">
                                <p className="text-danger font-italic" style={{ lineHeight: "17px" }}><small>Note:-Add file format with only JPG, JPEG, PNG format and File size must be less than 3 MB!</small></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            Question {index + 1}<span className="text-danger">*</span>
                          </div>
                          {(questions.length > 1) &&
                            <img className="poll_close_div" onClick={() => handleRemoveQuestion(index)} src={Closebutton} width={18} height={18}></img>}

                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <input
                              type="text"
                              ref={newQuestionRef}
                              className="form-control"
                              id={`Question${index + 1}`}
                              placeholder={`Question${index + 1}`}
                              value={question.question}
                              onChange={(e) => handleInputChange(index, -1, e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="col-md-4">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id={`customRadio${index}1`}
                                className="custom-control-input"
                                checked={question.answerType === "SingleSelect"}
                                onChange={(e) => handleAnswerTypeChange(index, "answerType", "SingleSelect")}
                              />
                              <label className="custom-control-label" htmlFor={`customRadio${index}1`}>Single select</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id={`customRadio${index}2`}
                                className="custom-control-input"
                                checked={question.answerType === "multiSelect"}
                                onChange={(e) => handleAnswerTypeChange(index, "answerType", "multiSelect",)}
                              />
                              <label className="custom-control-label" htmlFor={`customRadio${index}2`}>Multi Select</label>
                            </div>
                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="col-md-4">
                            <input
                              type="checkbox"
                              id={`textBoxCheckbox${index}`}
                              className="checkbox-clr mr-1"
                              checked={question.textBoxAddedOrNot}
                              onChange={(e) => handleAnswerTypeChange(index, 'textBoxAddedOrNot', e.target.checked ? 1 : 0)}
                            />
                            <label>Add Text Box</label>
                          </div>
                          <div className="col-md-4">
                            <input
                              type="checkbox"
                              id={`mandatoryCheckbox${index}`}
                              className="checkbox-clr mr-1"
                              checked={question.answerMandatoryOrNot}
                              onChange={(e) => handleAnswerTypeChange(index, 'answerMandatoryOrNot', e.target.checked ? 1 : 0)}
                            />
                            <label>Mandatory Answer</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 row justify-content-end pr-0">
                        <div className="AddNewHspbtnDiv w-auto">
                          <button className="addnewhspbtn mb-0" onClick={() => addOption(index)}>
                            Add Option
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3" id="optionAddDiv">
                        {question?.answers?.map((option, optionIndex) => (
                          <div key={optionIndex} className="row mb-3">
                            <div className="col-md-12" id={`optionAddDiv_${index}`}>
                              Option {optionIndex + 1}<span className="text-danger">*</span> :
                            </div>
                            <div className="col-md-12 row mx-0 justify-content-between">
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: "95%" }}
                                value={option}
                                onChange={(e) => handleInputChange(index, optionIndex, e.target.value)}
                              />
                              {(optionIndex >= 2 || question.answers.length > 2) &&
                                <img className="mt-2" onClick={() => handleRemoveOption(index, optionIndex)} src={Closebutton} width={18} height={18}></img>
                              }
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {questions.length > 1 &&
                <div className="col-md-12 row justify-content-end mb-2">
                  <div className="AddNewHspbtnDiv w-auto">
                    <button className="addnewhspbtn mb-0" onClick={addQuestion}>
                      Add Question
                    </button>
                  </div>
                </div>
              }
              <div className="col-md-12 text-right mb-3">
                <input
                  type="submit"
                  id="ffff"
                  className="smssetng-svebtn"
                  onClick={(e) => submitClick(e)}
                />
                 <input
                  type="submit"
                  value="Reset"
                  onClick={(e) => cancelClick(e)}
                  className="smssetng-cancelbtn"
                />
                 <input
                  type="submit"
                  value="Cancel"
                  onClick={(e) => goback(e)}
                  className="smssetng-resetbtn"
                />
              </div>
            </div>
          </div>
          {folderName !== null && <Footer />}
        </section>
      </Suspense>
    </div>
  );
}
export default AddEditIdaStore;
