import React, { Component, Suspense } from "react";
import DatePicker from "react-datepicker";
import { apiCalling } from "../apiService";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import newsfeedimg from "../image/icons/newsfeedimg.png";
import $ from "jquery";
import AWS from "aws-sdk";
var s3;
let fullFilePath
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class IDABannerSave extends Component {

  constructor(props) {
    super(props);

    this.state = {
      masterbannerid: '',
      bannerName: '',
      descriptionTxt: '',
      file: null,
      image: '',
      picture: '',
      AwsCheckAccessKey: '',
      AWSAccessKeyId: '',
      region: "us-east-1",
      bucket: '',
      bucketMainFolder: '',
      S3BucketHospitalImages: '',
      idaBranches: [],
      branchData: [],
      newDateFrom: '',
      toNewDate: '',
    }

    this.handleChange = this.handleChange.bind(this);
  }


  componentDidMount() {

    this.getIdaBranches();
    this.getAwsPath();

    if (this.props.match.params.bannerId) {
      this.getIDAResultList();
    }
  }

  getIDAResultList() {
    const resultList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getIdaBanner",
        siteId: "",
        max: "",
        offset: "",
        bannerId: this.props.match.params.bannerId === 'null' ? '' : this.props.match.params.bannerId,
      }),
    };
    apiCalling(resultList).then((data) => {
      if (data.success === "1") {
        let dateFromNew = moment(data.result[0].bannervalidfrom, "DD-MM-YYYY").format("YYYY-MM-DD");
        let dateToNew = moment(data.result[0].bannervalidto, "DD-MM-YYYY").format("YYYY-MM-DD");
        this.setState({
          bannerName: data.result[0].bannerName,
          descriptionTxt: data.result[0].bannerdescription,
          picture: data.result[0].bannerimagepath,
          bannershowbranch: data.result[0].bannershowbranch,
          branchData: data.result[0].branchdetails,
        });
        $("#fromDate").val(dateFromNew)
        $("#toDate").val(dateToNew)
        if (data.result[0].bannershowbranch === true) {
          $("#chkTxtBranch").prop('checked', true);
        }
        else {
          $("#chkTxtBranch").prop('checked', false);
        }
      }
    });

  }

  getIdaBranches() {
    const getData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getIdaBranches",
      }),
    };
    apiCalling(getData).then((data) => {
      if (data.success === "1") {
        this.setState({ idaBranches: data.result });
      }
    });
  }

  handleChange(event) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  }

  getAwsPath() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          AwsCheckAccessKey: data.configPathDetails[0].AwsCheckAccessKey,
        });
        this.setState({
          AWSAccessKeyId: data.configPathDetails[0].AwsCheckSecretKeyId,
        });

        this.setState({
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
        });
        this.setState({
          bucketMainFolder: data.configPathDetails[0].S3BucketMainFolder,
        });
        this.setState({
          S3BucketHospitalImages: data.configPathDetails[0].S3BucketHospitalImages,
        });

      }
    });

  }

  handleAppend() {

    let errormessage = "";

    if ($("#branchType").val() === "0") {
      errormessage = "Select IDA Branches"
      $("#branchType").focus();
    } else {
    }
    if (errormessage === "") {

      var data = {
        "branchId": $("#branchType").val(),
        "branchName": $("#branchType option:selected").text()
      }
      $('#branchType').prop('selectedIndex', 0);
      this.state.branchData.push(data)
      this.setState({ branchData: this.state.branchData })
    } else {
      $("#selectEdit").text(errormessage);
      $("#selectEdit").css("color", "red");
    }
  }

  categoryRemove(index) {
    const list = this.state.branchData;
    list.splice(index, 1)
    this.setState({ branchData: list })
  }

  saveHandle() {
    let fromDate = $("#fromDate").val()
    let toDate = $("#toDate").val()
    let errormessage = "";

    if (!this.state.bannerName) {
      errormessage = "Please Enter Banner Name"
      $("#bannerName").focus();
    } else if (!fromDate) {
      errormessage = "Please Select From Date"
      $("#fromDate").focus();
    } else if (!toDate) {
      errormessage = "Please Select To Date"
      $("#toDate").focus();
    } else if (!this.state.descriptionTxt) {
      errormessage = "Please Enter Banner Description"
      $("#descriptionTxt").focus();
    } else if (!this.state.image) {
      errormessage = "Please Upload Image"
      $("#attach").focus();
    }
    else if ($("#chkTxtBranch").is(":checked") === false) {
      if ($("#branchType").val() === "0" && this.state.branchData.length === 0) {
        errormessage = "Select The IDA Branch"
        $("#branchType").focus();
      }
    }
    else {
    }
    if (errormessage === "") {
      this.fileUpload(this.state.image)
      const saveBannerData = {
        method: 'POST',
        body: JSON.stringify({
          functionName: "saveIdaBanner",
          bannerId: this.props.match.params.bannerId === undefined ? '' : this.props.match.params.bannerId,
          bannerName: this.state.bannerName,
          bannerDescription: this.state.descriptionTxt,
          bannerImagePath: fullFilePath,
          validFrom: moment($("#fromDate").val(), "YYYY-MM-DD").format("DD/MM/YYYY"),
          validTo: moment($("#toDate").val(), "YYYY-MM-DD").format("DD/MM/YYYY"),
          showInAllBranches: ($("#chkTxtBranch").is(":checked") === true ? '1' : '0'),
          branchData: this.state.branchData,
        })
      }
      $(".loader").show();
      apiCalling(saveBannerData).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
          alert("Saved successfully")
          setTimeout(() => {
            window.location.href = "/dashboard/BannerIDAListing"
          }, 3000);
        } if (data.success === "0") {
          alert(data.errorMessage)
        }
      }) 
    }
    else {
      $("#bannerEdit").text(errormessage);
      $("#bannerEdit").css("color", "red");
    }
  }

  uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  fileChange = (e) => {
    let imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file only with JPG,JPEG,PNG format")
      return false;
    }
    if (imageFile.size > 3145728) {
      alert("File size must under 3 MB!");
      return false;
    }
    else {
      this.setState({ image: e.target.files[0] })
      this.setState({ picture: URL.createObjectURL(e.target.files[0]) });
    }
  }

  fileUpload(file) {

    AWS.config.update({
      accessKeyId: this.state.AwsCheckAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket: this.state.bucket + "/" + this.state.bucketMainFolder + "/" + this.state.S3BucketHospitalImages,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName = this.uuidv4() + file.name;
    fullFilePath = S3BucketMainFolder + fileName;
    this.setState({ image: fileName });
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          alert("successfully uploaded", data.message);
          return null;
        }
      }
    );
  }

  cancelHandle() {

    window.location.href = "/dashboard/BannerIDAListing"
  }

  srcTypeImage(ev) {
    ev.target.src = newsfeedimg
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />{" "}
            <div className='loader'></div>
          </section>
        </Suspense>
        <div className="content sh-ida">
          <div className="container">
            <div class="row  ">
              <h1 className="telehealthfeaturehead text-center ph-maintitle"> New IDA Banner </h1>
              <div class="col-md-12 p-0">
                <ul class="breadcrumb">
                  <li>
                    <a href="/dashboard/BannerIDAListing">IDA Banner</a>
                    <i class="fa fa-chevron-right"></i>
                  </li>
                  <li>New IDA Banner</li>
                </ul>
              </div>
              <div class="col-sm-12 my-2">
                <p id="bannerEdit"></p>
              </div>
              <div class="row sh-rw">
                <div class=" col-md-4">
                  <label class="col-md-12 label-text">Banner Name<span style={{ color: 'red' }}>*</span></label>
                  <div class="col-md-12">
                    <input type="text" class="input-design" id="bannerName" name="bannerName" value={this.state.bannerName} onChange={this.handleChange} /></div>
                </div>
                <div class=" col-md-4">
                  <label class="col-md-12 label-text sh-lbl "> From Date<span style={{ color: 'red' }}>*</span></label>


                  <input type="date" min={moment(new Date()).format("YYYY-MM-DD")} id="fromDate" name="fromDate" onChange={this.handleChange} />

                </div>
                <div class="  col-md-4">
                  <label class="col-md-12 label-text  sh-lbl"> To Date<span style={{ color: 'red' }}>*</span></label>
                  <input type="date" min={moment(new Date()).format("YYYY-MM-DD")} id="toDate" name="toDate" onChange={this.handleChange} />
                </div>
                <div class="  col-md-12">
                  <label class="col-md-12 label-text">Banner Description<span style={{ color: 'red' }}>*</span></label>
                  <div class="col-md-12 sh-textarea"><textarea class="input-design" style={{ height: "100px" }} id="descriptionTxt" name="descriptionTxt" value={this.state.descriptionTxt} onChange={this.handleChange}></textarea></div>
                </div>
                <div class="sh-blocks">
                  <div className="row">
                    <div class="  col-md-12">
                      <div className="sh-chck">
                        <input type="checkbox" name="chkTxtBranch" id="chkTxtBranch" />
                        <label class="col-sm-4 col-lg-5 label-text label-text-cln textleftAlign">Show In All Branches<span style={{ color: 'red' }}>*</span></label>

                      </div>
                    </div>

                  </div>
                  <div class="col-sm-12  px-0  px-sm-3">
                    <div class="col-sm-12 my-2">
                      <div className="row">
                        <p id="selectEdit"></p>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-sm-7 mb-2">
                        <select class="input-design w-75"
                          id="branchType"
                          name="branchType"
                          // value={this.state.languageNames}
                          onChange={this.handleChange}
                        >
                          <option value="0"> Select IDA Branch</option>
                          {this.state.idaBranches.map((obj, i) => (
                            <option value={obj.branchId}>{obj.branchName}</option>
                          ))}
                        </select>
                        <button class="btn-icons" id="btnSearch"><span class=" fa fa-plus" onClick={() => this.handleAppend()}></span></button></div>
                      <div className="col-md-5">
                        <div className="row ">
                          <div class="col-8 col-sm-6  ">
                            <div className="row">
                              {/* <div className="sh-addimg"> */}
                              <div className="sh-res-addimg ">
                                <div class="fileUpload-medical">
                                  <span>Add  Image</span>
                                  <input type="button" id="attach" name="attach" class="upload" />
                                  <input type="file" class="upload" onChange={this.fileChange} />
                                </div>

                              </div>
                            </div>
                          </div>
                          <div class="col-4 col-sm-6 sh-img-align">
                            <div className="row">
                              <img onError={this.srcTypeImage} src={this.state.picture} id={"showImg"} width="50"></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12  px-0  px-sm-3 sh-idabranch">
                    <h6 class="border-bottom pb-2">IDA Branch</h6>
                  </div>
                  <div class="col-sm-12  px-0  px-sm-3 sh-appnd">
                    {this.state.branchData.map((obj, index) => (
                      <div class="row my-2">
                        <div class="col-sm-7 mb-2" id={index}>
                          <input type="disabled" class="input-design w-75" defaultValue={obj.branchName} class="input-design w-75" />
                          <span class=" fa fa-trash color-maintext ml-5" onClick={(e) => this.categoryRemove()}></span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
              <div className=" sh-sve">
                <span className="divBtn" onClick={() => this.saveHandle()}>Save</span>
                <span className="divBtn ph-cancel" onClick={() => this.cancelHandle()} >Cancel</span>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
