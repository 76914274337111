
import { UploadImage } from "./apiService";
const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
export const uploadToS3 = async (image,path,fileName = false) => {
    try {
        const file =fileName?fileName:uuidv4();
        const formData = new FormData();
        formData.append("image", image, file);
        const data = await UploadImage(formData,path);
        return data.imageUrl;
    } catch (error) {
        console.error('Upload failed:', error);
        throw error;
    }
};