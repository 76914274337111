import React, { Component, Suspense } from "react";
import { apiCalling } from "../../apiService";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
// import Header from "../../layout/header";
// import Footer from "../../layout/footer";
import Logoimg from "../../image/icons/image-preview.png";
import Plus from "../../image/icons/plus.png";
import Closebtn from "../../image/icons/close-sm.png";
import DatePicker from "react-datepicker";
import moment from "moment";
import AWS from "aws-sdk";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { FormSelect } from "semantic-ui-react";
import Close from "../../image/icons/close-sm.png";

const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../../" + folderName + "/header"));
const Footer = React.lazy(() => import("../../" + folderName + "/footer"));
var s3;
var imageFile;
export default class AddEditIdaStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "",
      imageLogo: "",
      storeCode: "",
      adName: "",
      contactNo: "",
      seviceContactNumber: "",
      idaStoreDetailsId: "",
      idaStoreCategoryId: "",
      imagePath: "",
      AwsCheckAccessKey: "",
      pharmaImagePath: "",
      companyLogoName: "",
      AWSAccessKeyId: "",
      region: "us-east-1",
      bucket: "",
      bucketFolder: "",
      categoryList: [],
      imageNameOnly: "",
      imageNameWithPath: "",
      description: "",
      imagePreview: "",
    };
    this.numberHandleChange = this.numberHandleChange.bind(this);
    this.numberHandleChangeService = this.numberHandleChangeService.bind(this);
    this.textType = this.textType.bind(this);
  }

  componentDidMount() {
    this.imageUploadHandler();
    this.getStoreCategoryDetails();
    if (this.props.match.params.storeId) {
      this.editIdaStoreDetails();
    }
  }
  getStoreCategoryDetails() {
    const apiReq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getStoreCategoryDetails",
        keyWord: "",
      }),
    };
    apiCalling(apiReq).then((data) => {
      this.setState({
        categoryList: data.result,
      });
    });
  }

  editIdaStoreDetails() {
    const idaStoreData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getStoreListingByCategory",
        idaStoreCategoryId: "",
        keyWord: "",
        idaStoreDetailsId: this.props.match.params.storeId,
        Offset: "0",
        max: "10",
      }),
    };
    $(".loader").show();
    apiCalling(idaStoreData).then((data) => {
      if (data.success === "1") {
        // console.log(data);
        this.setState({
          name: data.result[0].storeName,
          address: data.result[0].address,
          contactNo: data.result[0].contactNo,
          description: data.result[0].storeDescription,
          seviceContactNumber: data.result[0].seviceContactNumber,
          idaStoreCategoryId: data.result[0].idaStoreCategoryId,
          idaStoreDetailsId: data.result[0].idaStoreDetailsId,
          imageNameOnly: data.result[0].imageNameOnly,
          imageNameWithPath:
            data.result[0].imagePathOnly + data.result[0].imageNameOnly,
        });
        // if (data.repApprovalData[0].hasWritePermission === true) {
        //   $("#PermissionStatus").prop("checked", true);
        // } else {
        //   $("#PermissionStatus").prop("checked", false);
        // }
      }
      $(".loader").hide();
    });
  }
  textType(e) {
    var code = "charCode" in e ? e.charCode : e.keyCode;
    if (!(code > 47 && code < 58)) {
      // lower alpha (a-z)
      alert("Only Numbers Accepted");
      e.preventDefault();
    }
  }

  numberHandleChange(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ contactNo: event.target.value });
    }
  }
  numberHandleChangeService(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ seviceContactNumber: event.target.value });
    }
  }
  descChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }
  cancelHandle() {
    window.location.href = "/adminManagement/idaStoresList";
  }
  srcTypeImage(ev) {
    ev.target.src = Logoimg;
  }
  fileChange = (e) => {
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 3145728) {
      alert("File size must under 3 MB!");
      return false;
    } else {
      var fileObj = e.target.files[0];
      this.setState({ imageNameWithPath: window.URL.createObjectURL(fileObj) });
      this.fileUpload(imageFile);
    }
  };
  imageUploadHandler() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          AwsCheckAccessKey: data.configPathDetails[0].AwsCheckAccessKey,
        });
        this.setState({
          AWSAccessKeyId: data.configPathDetails[0].AwsCheckSecretKeyId,
        });
        this.setState({
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
        });
        this.setState({
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
        });
        this.setState({
          pharmaImagePath: data.configPathDetails[0].pharmaImagePath,
        });
        // alert("hiiiii"+data.configPathDetails[0].companyLogoWithPath)
        // this.setState({
        //   companyLogoWithPath: data.configPathDetails[0].companyLogoWithPath,
        // });
      }
    });
  }
  fileUpload(file) {
    $(".loader").show();
    AWS.config.update({
      accessKeyId: this.state.AwsCheckAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket:
        this.state.bucket + "/" + this.state.bucketFolder + "/attachments",
      // +
      // "/" +
      // this.state.imageNameWithPath,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName = this.uuidv4() + file.name;
    let fullFilePath = S3BucketMainFolder + fileName;
    imageFile = fileName;
    this.setState({ imageNameWithPath: fileName });
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          this.setState({
            imageNameWithPath: data.Location,
            imageNameOnly: fileName,
          });
          $(".loader").hide();
          return null;
        }
      }
    );
  }
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  validateForm() {
    if (this.state.name === "") {
      alert("Store Name Required");
      $("#storeName").focus();
      return false;
    } else if (this.state.address === "") {
      alert("Address Required");
      $("#address").focus();
      return false;
    } else if (this.state.contactNo === "") {
      alert("Contact Number Required");
      $("#contactNo").focus();
      return false;
    } else if (!this.state.contactNo.match(/^[0-9.\b]{10}$/)) {
      alert("Please enter valid mobile number");
      $("#contactNo").focus();
    } else if (this.state.seviceContactNumber === "") {
      alert("Service Number Required");
      $("#serviceNo").focus();
      return false;
    } else if (!this.state.seviceContactNumber.match(/^[0-9.\b]{10}$/)) {
      alert("Please enter valid mobile number");
      $("#serviceNo").focus();
    } else if (this.state.idaStoreCategoryId === "") {
      alert("Store Category Required");
      $("#StoreCategoryId").focus();
      return false;
    }
    // else if (this.state.storeCode === "") {
    //   alert("Store Code Required");
    //   $("#storeCode").focus();
    //   return false;
    // }
    else if (this.state.imageNameWithPath === "") {
      alert("Image required.");
      return false;
    } else {
      return true;
    }
  }
  saveHandle = () => {
    if (this.validateForm()) {
      const apiReq = {
        method: "POST",
        body: JSON.stringify({
          functionName: "saveIdaStoreDetails",
          idaStoreDetailsId:
            this.state.idaStoreDetailsId !== ""
              ? this.state.idaStoreDetailsId
              : "",
          name: this.state.name,
          address: this.state.address,
          // storeCode: this.state.storeCode !== "" ? this.state.storeCode : "",
          idaStoreCategoryId: this.state.idaStoreCategoryId,
          contactNo: this.state.contactNo,
          seviceContactNumber: this.state.seviceContactNumber,
          image: this.state.imageNameOnly,
          description: this.state.description,
        }),
      };
      $(".loader").show();
      apiCalling(apiReq).then((data) => {
        // console.log(apiReq.body, "dddddddddddddddddddd");
        $(".loader").hide();
        if (data.success == 1) {
          alert("Saved Successfully");
          window.location.href = "/adminManagement/idaStoresList";
        } else {
          alert("Something went wrong while saving");
        }
      });
    }
  };
  imagePreview(image) {
    this.setState({
      imagePreview: image,
    });
    $("#analisePopup").attr("hidden", false);
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
            <div className="loader"></div>
            <div className="container mb-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboard">Dashboard</a>
                  </li>
                  {/* <li>
                <a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a>
              </li> */}
                  {/* <li>
                <a href="/adminManagement/pharmadashboardList/idaStoresList">
                  IDA Stores List
                </a>
              </li> */}
                  <li>
                    {this.props.match.params.storeId > 0 ? (
                      <a href="" className="current">
                        Edit IDA Store
                      </a>
                    ) : (
                      <a href="" className="current">
                        Add New IDA Store
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="addrepwrapperborder container pt-5 m-auto text-left purple_edit">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-4">
                      Name<span className="text-danger">*</span>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        id="storeName"
                        className="form-control"
                        placeholder="Store Name"
                        name="name"
                        value={this.state.name}
                        onChange={(e) => this.descChange(e)}
                      />
                    </div>
                  </div>
                </div>
                {this.props.match.params.storeId > 0 ? (
                  <div className="col-md-6 mb-3">
                    <div className="row">
                      <div className="col-md-4">
                        Ida Store Id<span className="text-danger">*</span>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          disabled
                          value={this.state.idaStoreDetailsId}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-4">
                      Address<span className="text-danger">*</span>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        id="address"
                        className="form-control"
                        name="address"
                        placeholder="Address"
                        value={this.state.address}
                        onChange={(e) => this.descChange(e)}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Store Code<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    name="storeCode"
                    id="storeCode"
                    value={this.state.storeCode}
                    onChange={(e) => this.descChange(e)}
                  />
                </div>
              </div>
            </div> */}
                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-4">
                      Ida Store Category<span className="text-danger">*</span>
                    </div>
                    {/* <div className="input_icon">
                        <a onClick={() => this.handleAddSpecialization()}>
                          <img src={Plus} />
                        </a>
                      </div> */}
                    <div className="col-md-8">
                      <select
                        type="text"
                        className="form-control"
                        name="idaStoreCategoryId"
                        id="StoreCategoryId"
                        value={this.state.idaStoreCategoryId}
                        onChange={(e) =>
                          this.setState({
                            idaStoreCategoryId: e.target.value,
                          })
                        }
                      >
                        <option value="">Select</option>
                        {this.state.categoryList.map((data) => (
                          <option value={data.idaStoreCategoryId}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-4">
                      Contact No<span className="text-danger">*</span>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        id="contactNo"
                        className="form-control"
                        name="contactNo"
                        maxLength="10"
                        placeholder="Contact No"
                        value={this.state.contactNo}
                        onChange={this.numberHandleChange}
                        onKeyPress={this.textType}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-4">
                      Service Contact Number
                      <span className="text-danger">*</span>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        id="serviceNo"
                        className="form-control"
                        name="seviceContactNumber"
                        maxLength="10"
                        placeholder="Service Contact Number"
                        value={this.state.seviceContactNumber}
                        onChange={this.numberHandleChangeService}
                        onKeyPress={this.textType}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-4">Description</div>
                    <div className="col-md-8">
                      <textarea
                        type="text"
                        id="serviceNo"
                        className="form-control"
                        name="seviceDescription"
                        placeholder="Description"
                        value={this.state.description}
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                        // onKeyPress={this.textType}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-4">
                      Image<span className="text-danger">*</span>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <div className="hspinfo-btnupload mb-auto">
                        <span>Upload</span>
                        <input
                          type="file"
                          className="hspinfoInputfieldupload"
                          onChange={this.fileChange}
                        />
                      </div>
                      {this.state.imageNameWithPath ? (
                        <div className="file_img">
                          <img
                            id="imageNameWithPath"
                            src={this.state.imageNameWithPath}
                            onError={this.srcTypeImage}
                            onClick={() =>
                              this.imagePreview(this.state.imageNameWithPath)
                            }
                            width="50"
                            alt="Upload"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-right mb-3">
                  <input
                    type="submit"
                    className="smssetng-svebtn"
                    value={
                      this.props.match.params.storeId > 0 ? "Update" : "Save"
                    }
                    onClick={() => this.saveHandle()}
                  />
                  <input
                    type="submit"
                    value="Cancel"
                    className="smssetng-cancelbtn"
                    onClick={this.cancelHandle}
                  />
                </div>
              </div>
            </div>
            {folderName !== null && <Footer />}
          </section>
        </Suspense>
        <div class="popup-overlay edit_modal" id="analisePopup" hidden>
          <div className="popup-contentDiv">
            <div className="modal-popup">
              <span
                className="closepopupqual"
                id="txtCancel"
                onClick={() => $("#analisePopup").attr("hidden", true)}
              >
                <img src={Close} alt="Close" className="img-fluid" width="25" />
              </span>
              <div className="content">
                <div class="HosptlMangemntTable text-center m-auto">
                  {this.state.imagePreview != "" ? (
                    <img src={this.state.imagePreview} className="img-fluid" />
                  ) : (
                    "No Image Found"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
